import Vue from 'vue';
import axios from 'axios';

const axiosParams = {
  baseURL: process.env.VUE_APP_API_NODE,
};
const axiosBackend = axios.create(axiosParams);

axiosBackend.interceptors.request.use((config) => {
  const token = Vue.$cookies.get('accessToken');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

export default axiosBackend;

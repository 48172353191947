import Vue from 'vue';
import Service from '@/config/service-identifiers';
import $services from '@/config/container';
import router from '@/router';
import { NOTIFICATIONS_ACTIONS, NOTIFICATIONS_GETTERS } from '@/store/notifications/notifications-store-constants';
import { AccessTokenKey } from '@/store/auth/auth.store.constants';

export function getDomain() {
  let domain = window.location.host.split('.').splice(-2).join('.');
  if (domain.startsWith('localhost:')) {
    domain = null;
  }

  return domain;
}

export default {
  state: {},
  getters: {
    token() {
      return Vue.$cookies.get(AccessTokenKey);
    },
    isAuthenticated(_state, { token }) {
      return !!token;
    },
  },
  actions: {
    async login(_ctx, { email, password, communitySlug }) {
      const response = await $services[Service.BackendClient].post('login', {
        email,
        password,
        communitySlug,
      });
      Vue.$cookies.set(AccessTokenKey, response.data, '1y', '/', getDomain(), true, 'Strict');
    },
    async loginsoap(_ctx, { user, password, communitySlug }) {
      const response = await $services[Service.BackendClient].post('loginsoap', {
        user,
        password,
        communitySlug,
      });
      Vue.$cookies.set(AccessTokenKey, response.data, '1y', '/', getDomain(), true, 'Strict');
    },
    async signup(_ctx, {
      email, communitySlug, referralType, referralID,
    }) {
      const response = await $services[Service.BackendClient].post('registeremail', {
        email,
        communitySlug,
        referralType,
        referralID,
      });
      return response;
    },
    async register(_ctx, {
      name, surname, password, token, communitySlug, invited, modality, timezone,
    }) {
      const response = await $services[Service.BackendClient].post('register', {
        name,
        surname,
        password,
        invited,
        token,
        modality,
        communitySlug,
        timezone,
      });
      Vue.$cookies.set(AccessTokenKey, response.data, '1y', '/', getDomain(), true, 'Strict');
      return response;
    },
    async password(_ctx, { email, communitySlug }) {
      const response = await $services[Service.BackendClient].post('password', {
        email,
        communitySlug,
      });
      return response;
    },
    async recoverPassword(_ctx, {
      password, repeatPassword, communitySlug, token,
    }) {
      const response = await $services[Service.BackendClient].post('recoverpassword', {
        password,
        repeatPassword,
        communitySlug,
        token,
      });
      return response;
    },
    async secureAuth(_ctx, { token }) {
      const response = await $services[Service.BackendClient].post('secureAuth', {
        secureToken: token,
      });
      Vue.$cookies.set(AccessTokenKey, response.data, '1y', '/', getDomain(), true, 'Strict');
    },
    async googleAuth(_ctx, { code }) {
      const response = await $services[Service.BackendClient].post('googleauth', {
        code,
      });
      Vue.$cookies.set(AccessTokenKey, response.data, '1y', '/', getDomain(), true, 'Strict');
    },
    async linkedinAuth(_ctx, { code }) {
      const response = await $services[Service.BackendClient].post('googleauth', {
        code,
        linkedin: true,
      });
      Vue.$cookies.set(AccessTokenKey, response.data, '1y', '/', getDomain(), true, 'Strict');
    },
    async SSOAuth(_ctx, { code }) {
      Vue.$cookies.set(AccessTokenKey, code, '1y', '/', getDomain(), true, 'Strict');
    },
    async logout({ dispatch, commit, rootGetters }) {
      let communitySlug;
      if (rootGetters.currentCollective?.slug) {
        communitySlug = rootGetters.currentCollective.slug;
      } else {
        window.location.href = process.env.VUE_APP_AUTH;
      }

      // if (rootGetters[NOTIFICATIONS_GETTERS.isSubscribedToPushNotifications]) {
      //   // await dispatch(NOTIFICATIONS_ACTIONS.toggleNotifications, false);
      // }

      console.log('Deleting cookies...');
      Vue.$cookies.remove(AccessTokenKey, '/', getDomain());
      Vue.$cookies.keys().forEach((cookie) => (cookie === 'accessToken' ? Vue.$cookies.remove(cookie) : ''));
      Vue.$cookies.keys().forEach((cookie) => (cookie === 'registeredTo' ? Vue.$cookies.remove(cookie) : ''));
      Vue.$cookies.keys().forEach((cookie) => (cookie === 'lastUrl' ? Vue.$cookies.remove(cookie) : ''));
      Vue.$cookies.keys().forEach((cookie) => (cookie === 'community' ? Vue.$cookies.remove(cookie) : ''));

      await router.replace({ 
        name: 'auth-login', 
        params: { 
          communityId: communitySlug 
        } 
      })
      .catch((error) => {
        console.log('error:', error);
      });

      commit('app/resetApp');
    },
  },
};

import Vue from 'vue';
import { isUserLoggedIn } from '@/auth/utils';
import { NOTIFICATIONS_ACTIONS } from '@/store/notifications/notifications-store-constants';
// import registerServiceWorker from '@/registerServiceWorker';
import { $themeBreakpoints } from '@themeConfig';

export default {
  namespaced: true,
  state: {
    windowWidth: 0,
    shallShowOverlay: false,
    breadcrumbs: null,
    pageTitle: null,

    status: '',
  },
  getters: {
    currentBreakPoint: (state) => {
      const { windowWidth } = state;
      if (windowWidth >= $themeBreakpoints.xl) return 'xl';
      if (windowWidth >= $themeBreakpoints.lg) return 'lg';
      if (windowWidth >= $themeBreakpoints.md) return 'md';
      if (windowWidth >= $themeBreakpoints.sm) return 'sm';
      return 'xs';
    },
    breadcrumbs: ({ breadcrumbs }) => breadcrumbs,
    empty: ({ status }) => status === '',
    loading: ({ status }) => status === 'pending',
    ready: ({ status }) => status === 'success',
    failure: ({ status }) => status === 'error',
  },
  mutations: {
    UPDATE_WINDOW_WIDTH(state, val) {
      state.windowWidth = val;
    },
    TOGGLE_OVERLAY(state, val) {
      state.shallShowOverlay = val !== undefined ? val : !state.shallShowOverlay;
    },
    SET_BREADCRUMBS(state, breadcrumbs) {
      state.breadcrumbs = breadcrumbs;
    },
    SET_PAGE_TITLE(state, pageTitle) {
      state.pageTitle = pageTitle;
    },
    resetApp(state) {
      state.status = '';
    },
    startApp(state) {
      state.status = 'pending';
    },
    appStarted(state) {
      state.status = 'success';
    },
    appFailed(state) {
      state.status = 'error';
    },
  },
  actions: {
    async startApp({ dispatch, commit }) {
      // registerServiceWorker({ scope: '/' });

      if (isUserLoggedIn()) {
        commit('startApp');
        const user = await dispatch('fetchLoggedUser', null, { root: true });
        //console.log('startApp user -> User: ', user);
        if (!user) {
          commit('appFailed');
          return;
        }
        if (user === 401) {
          await dispatch('logout', null, { root: true });
          return;
        }

        if (Vue.$workbox) {
          await dispatch(NOTIFICATIONS_ACTIONS.initializeServiceWorker, { root: true });
        }

        commit('appStarted');
      } else {
        commit('appStarted');
      }
    },
  },
};

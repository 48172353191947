const getChatbotElement = () => document.getElementsByClassName('crisp-client')[0];

/**
 * Services used to abstract the display status of the chatbot bubble.
 */
export default class ChatBotService {
  static hide() {
    // In my nectios, as there is no chatbot, it is necessary to validate if it exists so that the error does not appear in the console
    const chat = document.getElementsByClassName('crisp-client')[0];
    if(chat != null){
      getChatbotElement().style.setProperty('display', 'none', 'important');
    }
  }

  static show() {
    const chat = document.getElementsByClassName('crisp-client')[0];
    if(chat != null){
      getChatbotElement().style.display = '';
    }
  }
}
